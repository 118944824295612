import React from 'react';
import Layout from '../../../components/layouts';
import SEO from '../../../components/common/SEO';
import Banner from '../../../components/features/Banner'
import { graphql } from 'gatsby';
import SignUpBtn from '../../../components/features/SignUpBtn'
import Badge from '../../../components/badge/badge';
import ReactWOW from 'react-wow';
import './anniversarie.scss';

export const query = graphql`
  query anniversaries {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
    features: prismic {
        allFeatures {
            edges{
                node {
                    meta_title
                    meta_description
                    social_title
                    social_description
                    banner_title
                    banner_subtitle
                    banner_feature_name
                    banner_right_button_text
                    banner_right_button_source
                    question_text
                    question_answer
                    question_bullets {
                        bullet_text
                    }
                    question_image
                    video_source {
                        _linkType
                        __typename
                    }
                    video_statement {
                        text
                    }
                    information_list {
                        title
                        subtitle
                    }
                    steps_title
                    steps_subtitle
                    step_list {
                        image_source
                        title
                        subtitle
                    }
                      
                }
            }
        }
    }
  }
`

 export default function Anniversaries({data}){
    const features = data.features.allFeatures;
    const seo = {
        title: 'Workhub | Anniversaries',
        description: 'Celebrate another year in the books by letting workers choose an anniversary gift that resonates with them. Clothing with company branded items can show off your tenure while long service awards act as a special cherry on top.',
        image: '/features/anniversaries/anniversaries-hero.png',
    }

    const bannerProps = {
        title: 'Anniversaries',
        featureName: 'anniversaries',
        mockupImg: '/features/anniversaries/anniversaries-hero.png'

    }

    return (
        <Layout>
            <SEO {...seo} {...data.site}/>
            <Banner {...bannerProps}/>
            <main className="feature-main">
                <section className="feature-section anniversaries_header">
                    <div className='anniversaries_feature_header'>
                        <h1>Your Program Automated</h1>
                        <subtitle>Easily give everyone something for their anniversary</subtitle>
                    </div>
                    <div className='anniversaries_feature_info'>
                        <p>Turnover for organizations is expensive, both in terms of team morale and monetarily through lost productivity and output.</p>
                        <p>Recognizing a workers anniversary is an effective tool for increasing worker satisfaction and recognition. Recognizing workers for their hard work can help increase tenure and prevent turnover. 
                            Workhub’s Anniversaries component allows for the flexibility for workers to choose their own anniversary gift, increasing the chances they they will get something meaningful. 
                            Help foster a postive feeling towards your organization by starting your anniversary program today.</p>
                    </div>
                    <div className='anniversaries_feature_points'>
                        <div className='anniversaries_feature_list'>

                            <img src='/app-icons/layout-icons/check-mark-line.svg' alt="checkmark"  />
                            <p><strong>Stay Tax-Free:</strong> </p>
                            <div>
                                <p>Customized items may be a non-taxable benefit,* which does not deduct from the worker’s yearly non-cash gift allowance. </p>
                                <br />
                                <p className="anniversaries_hint">* We encourage you to meet with an accountant to ensure this program works for your organization.</p>
                            </div>

                            <img src='/app-icons/layout-icons/check-mark-line.svg' alt="checkmark"  />
                            <p><strong>Long-Service Options:</strong> </p>
                            <p>You can optionally give non-customized items every 5 years for milestone selections (long-service awards). 
                                <a target="_blank" href="https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/payroll/benefits-allowances/gifts-awards-social-events/gifts-awards-long-service-awards.html">Read more about the CRA’s long-service award policy.</a>
                            </p>

                            <img src='/app-icons/layout-icons/check-mark-line.svg' alt="checkmark"  />
                            <p> <strong>Budget Friendly:</strong> </p>
                            <p>Each year, selections can have its budget customized to match the needs of your business. Workhub’s items are always competitively priced and we carry a large variety of options.</p>

                            <img src='/app-icons/layout-icons/check-mark-line.svg' alt="checkmark"  />
                            <p> <strong>Set and Forget:</strong> </p>
                            <p>Including Anniversaries in your company’s culture and recognition program can help show that your company cares about their workers, which can reduce churn, increase productivity, and avoid burnout.</p>
                        </div>
                    </div>
                </section>
                <section className='feature-section'>
                    <SignUpBtn/>
                </section>

                <section className='feature-section'>
                    <div className='anniversaries_feature_options'>
                    <ReactWOW animation="fadeInLeft" delay="0.1s">
                        <div className='anniversaries_images_overlap'>
                            <img src="/features/anniversaries/anniversaries-template.png" alt="anniversaries program page" />
                        </div>
                    </ReactWOW>
                        <div>
                            <h1>Celebrate Every Year in Style</h1>
                            <p>
                                As part of your organization’s short service program, Workhub can send your workers an <strong>item customized with your logo.</strong> From coffee mugs to down parkas, Workhub has options for any budget and allows you to increase the value of your items over time to show that you value long-term commitment.
                                <br />
                                <br />
                                Items with your organization’s logo on them are <strong>generally considered a non-taxable benefit</strong>*, allowing your dollars to go further.
                                <br />
                                <br />
                                <p className="hint">* We encourage you to meet with an accountant to ensure this program works for your organization.</p>
                            </p>
                        </div>
                    </div>
                    <div className='anniversaries_feature_options'>
                        <div>
                            <h1>Special Options for Those Milestone Moments</h1>
                            <p>
                                As part of your organization’s long service program, Workhub can send your workers an <strong>item from our catalog valued under CA$500.</strong> You can also set a custom budget to match your organization’s needs. From paddleboards to wireless earbuds, Workhub has options for everyone.
                                <br />
                                <br />
                                Items received as part of a milestone (long-service award) are <strong>generally considered a non-taxable benefit</strong>*, allowing you to maximize savings while providing meaningful incentives to workers.                             
                                <br />
                                <br />
                                <p className="anniversaries_hint">* We encourage you to meet with an accountant to ensure this program works for your organization.</p>
                            </p>
                        </div>
                        <ReactWOW animation="fadeInRight" delay="0.1s">
                            <div> 
                                <img src="/features/anniversaries/anniversaries.png" alt="anniversaries celebration picture" />
                            </div>
                        </ReactWOW>
                    </div>
                </section>
                <section className='feature-section steps' id="overview-cards"> 
                    <h1>How it Works</h1>
                    <div className='anniversaries_how_it_works'>
                        <div className='empty'></div>
                        <Badge className="badge" content="1" size="large"/>
                        <ReactWOW animation="fadeInRight" delay="0.1s">
                            <div className='_option'>
                                <p className='step-header'>Setup Your Program</p>
                                <p>Choose a budget and which types of items are available for each year.</p>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className='anniversaries_how_it_works'>
                    <ReactWOW animation="fadeInLeft" delay="0.2s">
                        <div className='_option'>
                            <p className='step-header'>Let Your Company Culture Grow</p>
                            <p>Workers can choose their item when their anniversary date approaches. Once processed, it gets delivered directly to their door.</p>
                        </div>
                    </ReactWOW>

                        <Badge className="badge" content="2" size="large"/>
                        <div className='empty'></div>
                    </div>

                    <div className='anniversaries_how_it_works'>
                        <div className='empty'></div>
                        <Badge className="badge" content="3" size="large"/>
                        <ReactWOW animation="fadeInRight" delay="0.3s">
                            <div className='_option'>
                                <p className='step-header'>See the Results</p>
                                <p>Visibly see your tenure as workers wear their items from each year. Can help decrease company turnover, and increase satisfaction.</p>
                            </div>
                        </ReactWOW>

                    </div>

                </section>
            </main>
        </Layout>
    )
 }